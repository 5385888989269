exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-canopy-tsx": () => import("./../../../src/pages/canopy.tsx" /* webpackChunkName: "component---src-pages-canopy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-strategies-tsx": () => import("./../../../src/pages/strategies.tsx" /* webpackChunkName: "component---src-pages-strategies-tsx" */),
  "component---src-pages-tvls-tsx": () => import("./../../../src/pages/tvls.tsx" /* webpackChunkName: "component---src-pages-tvls-tsx" */),
  "component---src-pages-vaults-tsx": () => import("./../../../src/pages/vaults.tsx" /* webpackChunkName: "component---src-pages-vaults-tsx" */)
}

